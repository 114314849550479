import React, { useState, useEffect } from "react"
import { Link } from "gatsby"

//plugins
import addToMailchimp from "gatsby-plugin-mailchimp"

import { trackDownloadWithPixel } from "../services/pixels.service"

//components
import SignupModal from "../components/newsletter/signupModal"
import SuccessModal from "../components/newsletter/successModal"

// assets
import Logo from "../assets/images/footer-logo.svg"
import Fb from "../assets/images/footer/icon-fb.svg"
import Ig from "../assets/images/footer/icon-ig.svg"
import Twt from "../assets/images/footer/icon-twitter.svg"
import Yt from "../assets/images/footer/icon-yt.svg"
import Tt from "../assets/images/footer/icon-tt.svg"
import Google from "../assets/images/footer/btn-google.svg"
import Apple from "../assets/images/footer/btn-apple.svg"
import Lines from "../assets/images/footer/lines-footer.svg"
import modalImg from "../assets/images/illustration-signupsuccess.svg"

const Footer = () => {
  //for newsletter
  const [email, setEmail] = useState("")
  const [access, setAccess] = useState(false)
  const [success, setSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  useEffect(() => {
    if (access || success === true) {
      document.body.style.overflow = "vissible"
    } else {
      document.body.style.overflow = "unset"
    }
  })

  const handleSubmit = e => {
    e.preventDefault()

    addToMailchimp(email)
      .then(data => {
        // alert(data.msg)
        if (data.result === "error") {
          setErrorMessage(`This email is already subscribed to the newsletter`)
          return
        } else {
          setSuccess(!success)
          setAccess(false)
          setErrorMessage("")
        }
      })
      .catch(error => {
        alert("Check email address")
      })
  }

  const handleEmailChange = event => {
    setEmail(event.currentTarget.value)
  }

  const showModal = event => {
    setAccess(!access)
    event.preventDefault()
  }

  const closeSuccess = () => {
    setSuccess(false)
  }

  return (
    <>
      <div className="footer bg-design">
        <div className="container" style={{ alignItems: "center" }}>
          <div className="content-wrapper">
            <img src={Logo} alt="" className="logo" />
            <div className="social-wrapper">
              <a
                className="social-icon"
                href="https://www.facebook.com/servbees"
                target="__blank"
              >
                <div className="icon-holder">
                  <img src={Fb} className="icon" alt="Facebook Page" />
                </div>
              </a>
              <a
                className="social-icon"
                href="https://www.instagram.com/servbees"
                target="__blank"
              >
                <div className="icon-holder">
                  <img src={Ig} className="icon" alt="Instagram" />
                </div>
              </a>
              <a
                className="social-icon"
                href="https://twitter.com/servbees"
                target="__blank"
              >
                <div className="icon-holder">
                  <img src={Twt} className="icon" alt="Twitter" />
                </div>
              </a>
              <a
                className="social-icon"
                href="https://www.youtube.com/c/Servbees"
                target="__blank"
              >
                <div className="icon-holder">
                  <img src={Yt} className="icon" alt="YouTube" />
                </div>
              </a>
              <a
                className="social-icon"
                href="https://www.tiktok.com/@servbees"
                target="__blank"
              >
                <div className="icon-holder">
                  <img src={Tt} className="icon" alt="TikTok" />
                </div>
              </a>
            </div>
            <div className="container link-container">
              <div className="link-wrapper">
                <div className="text-holder">
                  <div className="footer-list-item">
                    <Link to="/terms-of-use" className="footer-link">
                      Terms of Use
                    </Link>
                  </div>
                  <div className="footer-list-item">
                    <Link to="/privacy-policy" className="footer-link">
                      Privacy Policy
                    </Link>
                  </div>
                  <div className="footer-list-item">
                    <Link to="/press-kit" className="footer-link">
                      Digital Press Kit
                    </Link>
                  </div>
                  <div className="footer-list-item">
                    <Link to="/" className="footer-link" onClick={showModal}>
                      Subscribe to our Newsletter
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-wrapper">
            <p className="label">
              Bee one of the first to experience beeyanihan. Download now!
            </p>
            <ul className="app-wrapper">
              <li className="app-item">
                <a
                  href="https://apps.apple.com/ph/app/servbees/id1530137634"
                  target="__blank"
                >
                  <img
                    src={Apple}
                    className="app-icon"
                    alt="download on apple store"
                  />
                </a>
              </li>
              <li className="app-item">
                <a
                  href="https://play.google.com/store/apps/details?id=com.servbees"
                  target="__blank"
                  onClick={() => trackDownloadWithPixel('android')}
                >
                  <img
                    src={Google}
                    className="app-icon"
                    alt="download on google play store"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="container text-container">
          <div className="content-wrapper">
            <div className="text-holder">
              <p>
                For Partnerships and Press Inquiries
                <br />
                Send us an email at{" "}
                <a href="mailto:hello@servbees.com">hello@servbees.com</a> and
                we'll get right back to you.
              </p>
            </div>
          </div>
          <div className="content-wrapper">
            <div className="copyright-holder">
              <br />
              <p>
                © {new Date().getFullYear()} 360Pixels Pte Ltd. All rights
                reserved.
              </p>
              <p>
                Made with{" "}
                <span role="img" aria-label="heart">
                  ❤️
                </span>{" "}
                in SG & PH
              </p>
            </div>
          </div>
        </div>
        <div className="lines">
          <img src={Lines} alt="" />
        </div>
      </div>
      {access ? (
        <SignupModal
          errorMessage={errorMessage}
          showModal={showModal}
          handleSubmit={handleSubmit}
          handleEmailChange={handleEmailChange}
          access={access}
          success={success}
          inputValue={email}
        />
      ) : (
        ""
      )}
      {success ? (
        <SuccessModal
          handleSubmit={handleSubmit}
          access={access}
          success={success}
          closeSuccess={closeSuccess}
          modalImg={modalImg}
          mainText={"What the buzz! You're now on our email list."}
          subText={"Check your email and get buzzing with Servbees!"}
        />
      ) : (
        ""
      )}
    </>
  )
}

export default Footer
